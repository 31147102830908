/*
 * N49 Framework
 * 2020-2021
 * Author: Wasim al Hafez
 */

(function ($) {
    'use strict';

    let N49 = {

        debug: false,

        init: function (debug = false) {

            this.debug = debug;

        },


    };


    N49.init(true);


}(jQuery));